// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CompanyApiMethodsCls {
    async companyList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка компаний
      // filters: id, name, is_active, is_system, is_agency, is_hotelier, is_service, is_transfer
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('companyList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async companyCreate(wh, data) {
      // data: Company
      // Создание компании
      console.log('companyCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyCreateexCreate(wh, data) {
      // data: CompanyEntityCreate
      // Создание Компании и юрлица
      console.log('companyCreateexCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.createex.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyRetrieve(wh, id) {
      // Получение компании
      console.log('companyRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('account__company', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'account__company', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyUpdate(wh, id, data) {
      // data: Company
      // Обновление модели компании
      console.log('companyUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyDelete(wh, id) {
      // Удаление компании
      console.log('companyDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyActivatePartial(wh, id, data) {
      // data: CompanyActivate
      // Изменение поля is_active для компании (Company)
      console.log('companyActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async companyIdentitiesList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Все доступные Identities компании, за исключением CUSTOMER
      // filters: created_at, updated_at, company, user, email, phones, exact_position_name, role,
      //          is_active, is_deleted, is_managed
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('companyIdentitiesList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.identities.list(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async companyPhonesList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Все внутренние телефоны компании
      // filters: created_at, updated_at, company, number, r10, comment, own
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('companyPhonesList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.company.phones.list(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.companyApi = new CompanyApiMethodsCls()
}

