// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class UserApiMethodsCls {
    async userList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка User
      // filters: phone, name, surname, patronymic, birthday, location_id, is_superuser, is_active,
      //          is_staff, is_demo, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('userList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async userCreate(wh, data) {
      // data: UserPost
      // Создание User
      console.log('userCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userPasswordCreate(wh, data) {
      // data: UserChangePassword
      // Изменение пароля пользователем
      console.log('userPasswordCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.password.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userRetrieve(wh, id) {
      // Получение User
      console.log('userRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('account__user', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'account__user', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userUpdate(wh, id, data) {
      // data: UserUpdate
      // Обновление модели User
      console.log('userUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userDelete(wh, id) {
      // Удаление User
      console.log('userDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userActivatePartial(wh, id, data) {
      // data: UserActivate
      // Изменение поля is_active для User
      console.log('userActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async userPasswordPartial(wh, id, data) {
      // data: UserChangePassword
      // Изменение пароля пользователя, руководителем компании, если пользователь is_managed
      console.log('userPasswordPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.account.user.password.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.userApi = new UserApiMethodsCls()
}

