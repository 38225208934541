import { getIdentities } from '@/api/identityMethods'

const state = () => ({
  identitys: [],
})

const getters = {
  identitys: (state) => {
    return state.identitys
  },
}

const mutations = {
  SET_IDENTITYS(state, payload) {
    state.identitys = payload
  },
}

const actions = {
  async SET_IDENTITYS({ commit }, payload) {
    const response = payload ? payload : await getIdentities()
    commit('SET_IDENTITYS', response)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
