// import { useStore } from 'vuex'
// const vuexStore = useStore()
// import {useRouter} from "vue-router";

const window = 'AUTH'

export default ctx => {
  class AuthService  {

    setToken(token) {
      this.tokenKey = token
      localStorage.setItem('token', token)
      localStorage.setItem('loggedIn', true)
    }

    getTokenKey() {
      return localStorage.getItem('token')
    }

    async isAuthenticated(parentLogin=false, retry_if_none=false, point) {

      if (this._token) {
        console.log(`isAuthenticated(${point}) - token exists`)
        return true
      }
      if (!retry_if_none) {
        return false
      }

      console.log(`isAuthenticated(${point}) - token not found`)
      try {
        this._token = await ctx.$services.accountApi.authinfoRetrieve(window)
      }
      catch (e) {
        console.log(`isAuthenticated(${point})  failed  `, e)
        if (e.response) {
          console.log(`isAuthenticated(${point}) authinfoRetrieve failed with code `, e.response.status)
          if (e.response.status === 401) {
            if (!parentLogin) {
              // const router = useRouter()
              console.log(`isAuthenticated(${point}) - redirect to login`)
              // await router.push({name: 'Login'})
              return false
            }
            console.log(`isAuthenticated(${point}) - return false`)
            return false
          }
        }
        throw (e)
      }
      console.log(`isAuthenticated(${point}) loaded token:`, this._token)
      this._identity = await ctx.$services.identityApi.identityRetrieve(window, this._token.identity.id)  // TODO  identity.id ==> identity
      console.log(`isAuthenticated(${point}) authenticate identity:`, this._identity)
      console.log(`isAuthenticated(${point}) succeed true`)
      return true
    }

    resetAuthentication() {
      this.tokenKey = null
      this._token = null
      this._identity = null

      ctx.$services.storage.clr('AUTH')
      localStorage.setItem('token', null)
      localStorage.setItem('loggedIn', false)
    }

    async authenticate(login, password) {
      ctx.$services.storage.clr('AUTH')
      this._token = await ctx.$services.authApi.personalCreate(window, {login, password})
      console.log('authenticate token:', this._token)
      this.setToken(this._token.key)

      this._identity = await ctx.$services.identityApi.identityRetrieve(window, this._token.identity.id)  // TODO  identity.id ==> identity
      console.log('authenticate identity:', this._identity)
    }

    async getIdentity() {
      if (!this._identity) {
        this.resetAuthentication()
      }
      return this._identity
    }

    async changeIdentity (identity) {
      ctx.$services.storage.clr('AUTH')
      this._token = await ctx.$services.accountApi.changeCreate(window, {identity})
      console.log('changeIdentity token:', this._token)
      this.setToken(this._token.key)

      this._identity = await ctx.$services.identityApi.identityRetrieve(window, this._token.identity.id)  // TODO  identity.id ==> identity
      console.log('authenticate identity:', this._identity)
    }
  }
  console.log('AuthService created')
  ctx.$services.auth = new AuthService()
}
