import { createStore } from 'vuex'
import { getTypesComment } from '@/api/comments'
import user from '@/store/modules/user'
import identity from '@/store/modules/identity'
import company from '@/store/modules/company'
import aplications from '@/store/modules/aplications'

export default createStore({
  state: {
    baseUrl: 'http://dev-api.rnsb.ru',
    asideVisible: false,
    toasts: [],
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    commentsTypes: [],
  },
  getters: {
    toasts: (state) => {
      return state.toasts
    },
    baseUrl: (state) => {
      return state.baseUrl
    },
    commentsTypes: (state) => {
      return state.commentsTypes
    },
  },
  mutations: {
    SET_COMMENTS_TYPES(state, payload) {
      state.commentsTypes = payload
    },
    pushToast(state, payload) {
      state.toasts = [...state.toasts, payload]
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state, payload) {
      console.log("🚀 ~ file: index.js ~ line 40 ~ toggleSidebar ~ payload", payload)
      localStorage.setItem('toggleSidebar', payload)
      state.sidebarVisible = payload
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    changeBaseUrl(state, payload) {
      console.log(payload)
      state.baseUrl = payload
    },
  },
  actions: {
    async SET_COMMENTS_TYPES({ commit }, payload) {
      const response = payload ? payload : await getTypesComment()
      commit('SET_COMMENTS_TYPES', response)
    },
  },
  modules: {
    user,
    identity,
    aplications,
    company
  },
})
