import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function createIdentity(data) {
  const result = await useAPI.request({ config: endpoints.account.createIdentity, body: data })
  return result.data
}

async function getIdentity(id) {
  const result = await useAPI.request({ config: endpoints.account.identity(id) })
  return result.data

}
async function getIdentities() {
  const result = await useAPI.request({ config: endpoints.account.identities })
  return result.data
}

async function activeIdentity(id, isActive) {
  console.log("file: indentity.js ~ line 10 ~ activeIdentity ~ isActive", isActive)
  const result = await useAPI.request({ 
    config: endpoints.account.activeIdentity(id), 
    body: { is_active: isActive } 
  })
  return result.data
}

export { getIdentities, getIdentity, activeIdentity, createIdentity }
