// Здесь перечислены все доступные ручки
// они должны в точности соответствовать тому что реализуется Бэком (смотр swagger)
// идентификаторы должны формироваться по следующему правилу
//
//  если есть только 1 ручка для данного URL
//
// PATCH  api/account/user/${id}/activate/    =>  account: { user : { activate: {...}
// POST   api/auth/customer/                  =>  auth: { customer: {} }

//  если есть несколько ручек для данного URL с разными методами то
//
// GET api/legal/entity/                      =>  legal: { entity: { list: {...} }
// POST api/legal/entity/                     =>  legal: { entity: { create: {...} }
// GET api/legal/entity/${id}/                =>  legal: { entity: { create: {...} }
// PUT api/legal/entity/${id}/                =>  legal: { entity: { update: {...} }
// PATCH api/legal/entity/${id}/              =>  legal: { entity: { partial: {...} }
// DELETE api/legal/entity/${id}/             =>  legal: { entity: { delete: {...} }
//
// если ручка уже описана но имеет другое название - их временно оставляем но помечаем  комментарием legacy
// ручки идут строго в том же порядке что и в свагере
// "scripts" называть как "script" - на бэке мы переделаем



export default {
  auth: {
    signIn: { url: 'api/auth/customer/', method: 'POST'},
    refreshToken: { url: `api/auth/refresh/`, method: 'POST', json: true },
    cloneToken: { url: `api/auth/clone/`, method: 'POST', json: true },
    registerUser: (part) => ({ url: `api/auth/register/${part}/`, method: 'POST', json: true }),
    restorePassword: (part) => ({ url: `api/auth/restore/${part}/`, method: 'POST', json: true }),
  },
  script: {
    startScript: { url: 'api/scripts/script/play/1/', method: 'GET' },
  },
  core: {
    getAplications: (params) => ({ url: 'api/core/lead/', method: 'GET', json: true, params }),
    createApplications: { url: 'api/core/lead/', method: 'POST' },
    deleteApplications: id => ({ url: `api/core/lead/${id}/`, method: 'DELETE' }),
    editApplications: id => ({ url: `api/core/lead/${id}/`, method: 'PUT', json: true }),
    comment: { url: 'api/core/comment/', method: 'POST', json: true },
    comments: (name, id) => ({ url: `api/core/comment/${name}/${id}`, method: 'GET' }),
    typesComment: { url: 'api/core/types/', method: 'GET' },
    loadGeneric: (content_types, id) => ({ url: `api/core/generic_get/${content_types}/${id}`, method: 'GET' }),
    autocomplete: ({ url: `api/core/autocomplete/`, method: 'PUT', json: true }),
  },
  entity: {
    entity: (params = {}) => ({ url: 'api/legal/entity/', method: 'GET', params}),
    editEntity: (id) => ({ url: `api/legal/entity/${id}/`, method: 'PUT', json: true}),
    addEntity: { url: `api/legal/entity/`, method: 'POST', json: true },
    activeEntity: (id) => ({ url: `api/legal/entity/${id}/`, method: 'PATCH', json: true}),
    deleteEntity: id => ({ url: `api/legal/entity/${id}/`, method: 'DELETE' })
  },
  account: {
    authInfo: { url: 'api/account/authinfo/', method: 'GET'},
    company: (params = {}) => ({ url: 'api/account/company/', method: 'GET', params}),
    addCompany: { url: 'api/account/company/createex/', method: 'POST', json: true },
    editCompany: (id) => ({ url:  `api/account/company/${id}/`, method: 'PUT', json: true }),
    activeCompany: (id) => ({ url: `api/account/company/${id}/`, method: 'PATCH', json: true }),
    myroles: { url: 'api/account/myroles/', method: 'GET'},
    user: id => ({ url: `api/account/user/${id}/`, method: 'GET'}),
    users: (params) => ({ url: 'api/account/user/', method: 'GET', params}),
    activeUser: (id) => ({ url: `api/account/user/${id}/activate/`, method: 'PATCH', json:true }),
    createUser: (json) => ({ url: `api/account/user/`, method: 'POST', json }),
    deleteUser: (id) => ({ url: `api/account/user/${id}/`, method: 'DELETE'}),
    editUser: (id) => ({ url: `api/account/user/${id}/`, method: 'PUT', json: true}),
    identity: (id) => ({ url: `api/account/identity/${id}/`, method: 'GET'}),
    identities:  (id, params) => ({ url: `api/account/company/${id}/identities/`, method: 'GET', params}),
    deleteIdentity: id => ({ url: `api/account/user/${id}/`, method: 'DELETE'}),
    activateIdentity: (id) => ({ url: `api/account/identity/${id}/activate/`, method: 'PATCH', json: true }),
    editIdentity: (id) => ({ url: `api/account/user/${id}/`, method: 'PUT', json: true}),
    createIdentity: ({ url: `api/account/identity/`, method: 'POST' }),
  },
  contract: {
    contracts: (type, params) => ({ url: `api/legal/contract/${type}/`, method: 'GET', params}),
    contract: (type, id) => ({ url: `api/legal/contract/${type}/${id}/`, method: 'GET' }),
    createContracts: (type) => ({ url: `api/legal/contract/${type}/`, method: 'POST', json: true}),
    editContracts: (type, id) => ({ url:`api/legal/contract/${type}/${id}/`, method: 'POST', json: true}),
    activeContract: (id) => ({ url: `api/legal/contract/${id}/change_active/`, method: 'PATCH', json: true}),
  },
  rooms: {
    getRooms: id => ({ url: `api/hms/hotel/${id}/cross/rooms/`, method: 'GET' }),
    getRoomsByDates: id => ({ url: `api/hms/hotel/${id}/cross/rooms/`, method: 'PUT', json: true })
  },
  media: {
    deleteFile: { url: `api/media/file/`, method: 'DELETE'},
    deleteImage: { url: `api/media/image/`, method: 'DELETE'},
    uploadFile: { url: `api/media/file/upload/`, method: 'POST'},
    uploadImage: { url: `api/media/image/upload/`, method: 'POST'},
  },
  refbook: {
    getHotels: (limit, offset) => {
      const params = {}
      if (limit) params.limit = limit
      if (offset) params.offset = offset
      return { url: 'api/refbook/hotel/', method: 'GET', params}
    },
    getHotel: id => ({ url: `api/refbook/hotel/${id}/`, method: 'GET' }),
    setActivityHotel: (id) => ({ url: `api/refbook/hotel/${id}/active/`, method: 'PATCH'})
  }
}
