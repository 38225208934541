// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class CommentApiMethodsCls {
    async commentList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка комментариев
      // filters: owner, object_type
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('commentList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async commentCreate(wh, data) {
      // data: Comment
      // Создание комментария
      console.log('commentCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async commentListForObjectList(wh, data_type, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка комментариев для объекта
      // filters: owner, object_type
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('commentListForObjectList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.listForObject.list(data_type, id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async commentRetrieve(wh, id) {
      // Получение конкретного комментария
      console.log('commentRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('core__comment', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'core__comment', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async commentUpdate(wh, id, data) {
      // data: Comment
      // Изменение комментария
      console.log('commentUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async commentDelete(wh, id) {
      // Удаление комментария
      console.log('commentDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async commentActivatePartial(wh, id, data) {
      // data: CommentActivate
      // Изменение поля is_active для комментария
      console.log('commentActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.comment.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.commentApi = new CommentApiMethodsCls()
}

