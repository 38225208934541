<template>
  <CFooter @click="test" class="app-footer">
    <div>
      <a href="https://cp.nsb.su/" target="_blank">НСБ©</a>
      <span class="ms-1"> 2011-2019 Национальная Система Бронирования </span>
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Сайт создан на платформе</span>
      <a href="https://cp.nsb.su/about-platform/">НСБ-Агент</a>
    </div>
    <div class="d-flex gap ms-auto">
      <span>
        {{ new Date().toLocaleDateString() }}<sup>{{ time }}</sup>
      </span>
      <span>Клиент {{ frontVersion }}</span>
      <span>Сервер prod-2.33.26-12.11.23.53</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      state: true,
      time: '',
      interval: null,
      frontVersion: process.env.VUE_APP_VERSION,
    }
  },
  mounted() {
    this.interval = setInterval(this.updateClock, 100)
  },
  methods: {
    updateClock: function () {
      let now = new Date()
      const h = this.leftPad('' + now.getHours())
      const m = this.leftPad('' + now.getMinutes())
      const s = this.leftPad('' + now.getSeconds())
      this.time = `${h} ${m} ${s}`
    },
    leftPad: function (str) {
      const leftPad = '00'
      return leftPad.substring(0, leftPad.length - str.length) + str
    },
    test() {
      console.log('object')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-footer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #ffff;
  border-top: 1px solid #c8ced3;
  min-height: 50px;
}
a {
  color: #20a8d8;
  text-decoration: none;
  background-color: transparent;
}
</style>
