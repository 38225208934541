import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function signIn(login, password) {
  const config = endpoints.auth.signIn
  const body = { login, password }
  const result = await useAPI.request({ config, body })

  return result
}

async function authInfo() {
  const result = await useAPI.request({ config: endpoints.account.authInfo })
  return result
}

async function getAccountComapny() {
  const result = await useAPI.request({ config: endpoints.account.company() })
  return result
}

async function testFetch(path, method, body, params, json, server) {
  const result = await useHttp(path, method, body, params, json, server)

  return result.payload
}

async function getUser(id) {
  const result = await useAPI.request({ config: endpoints.account.user(id) })
  return result.data
}

async function getUsers(props = null) {
    const result = await useAPI.request({ config: endpoints.account.users(props) })
    return result
}

async function activeUser(id, isActive) {
  console.log("file: userMethods.js ~ line 60 ~ activeUser ~ id", id)
  console.log("file: userMethods.js ~ line 60 ~ activeUser ~ isActive", isActive)
  const result = await useAPI.request({
    config: endpoints.account.activeUser(id),
    body: { is_active: isActive }
  })
  return result
}

async function createUser(data) {
  const result = await useAPI.request({ config: endpoints.account.createUser(true), body: data })
  return result.data
}

async function editUser(data) {
  const result = await useAPI.request({
    config: endpoints.account.editUser(data.id),
    body: data
  })
  return result.data
}

async function deleteUser(id) {
  const result = await useAPI.request({ config: endpoints.account.deleteUser(id)})
  return result.data
}

export {




  signIn,
  authInfo,
  testFetch,
  getUser,
  getUsers,
  activeUser,
  createUser,
  deleteUser,
  editUser,
  getAccountComapny,
}
