import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function getComments(id = '', object_type_name = '') {
  const result = await useAPI.request({ config: endpoints.core.comments(object_type_name, id) })
  return result.data
}

async function getTypesComment() {
  const result = await useAPI.request({ config: endpoints.core.typesComment })
  return result.data
}

async function sendComments(props) {
  const result = await useAPI.request({ config: endpoints.core.comment, body: props })
  return result.data
}

export { getComments, sendComments, getTypesComment }
