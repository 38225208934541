/* eslint-disable */


/*
Хранилище объектов

Помещает объект или массив объектов в хранилище.
При наличии в хранилище объекта данные нового объекта заносятся в старый.
При отсутствии в хранилище, объект помещается хранилище.
Возвращается объект, находящийся в хранилище.
Если был передан массив объектов то возвращается соответствующий массив объектов из хранилища.

*/

export default ctx => {

  // console.log('------------------- StorageService ----------------')

  class StorageService {
    constructor() {
      this._storage = {}
      this.data = {}
    }

    storage(window, data_type) {
      if (this._storage[window] === undefined) {
        this._storage[window] = {}
      }
      if (this._storage[window][data_type] === undefined) {
        this._storage[window][data_type] = {}
      }
      return this._storage[window][data_type]
    }

    // не использовать
    // TODO будет удалено
    setItem(data, mainDataName) {
      console.log(`setItem(${data}, ${mainDataName})`)
    }

    // не использовать
    // TODO будет удалено
    getItemById(name, value) {
      // console.log(`getItemById(${data}, ${mainDataName})`)
      return this.data?.[name]?.find(item => item.id === value)
    }

    // не использовать
    // TODO будет удалено
    reset() {
      // console.log(`reset`)
    }

    _ids_key(window) {
      let ids = [];
      if (this._storage[window]) {
        // //console.log('_ids_key 2', window)
        Object.keys(this._storage[window]).forEach(data_type => {
          // //console.log('_ids_key 3', data_type)
          Object.keys(this._storage[window][data_type]).forEach(id => {
            // //console.log('_ids_key 4', id)
            ids.push({data_type:data_type, id:id})
          });
        });
      }
      return ids
    }

    _ids(window = null) {
      // //console.log('_ids', window)
      if (window !== null) {
        // //console.log('_ids 2', window)
        return this._ids_key(window)
      }
      // //console.log('_ids 3', window)
      let ids = []
      // //console.log('_ids 4', window)
      Object.keys(this._storage).forEach(key => {
        // //console.log('_ids 5', window)
        ids = [...ids, ...this._ids_key(key)]
      });
      return ids
    }

    _unsubscribe(obj_list) {
      // obj_list.forEach(obj => console.log(`unsubscribe ${obj.data_type}#${obj.id}`))
    }

    _subscribe(obj_list) {
      // obj_list.forEach(obj => console.log(`subscribe ${obj.data_type}#${obj.id}`))
    }

    _put_array(obj_list, window, subscribe) {
      let new_list = []
      obj_list.forEach(val => new_list.push(this._put_single(val, window, false)))
      if (subscribe) {
        this._subscribe(obj_list)
      }
      return new_list
    }

    _put_single(obj, window, subscribe) {
      let self;
      if (this.storage(window, obj['data_type'])[obj['id']] === undefined) {
        // console.log('put==', 2)
        this.storage(window, obj['data_type'])[obj['id']] = obj
        self = this.storage(window, obj['data_type'])[obj['id']]
      }
      else {
        // console.log('put==', 3)
        self = this.storage(window, obj['data_type'])[obj['id']]
        Object.keys(self).forEach(key => delete self[key]);
        Object.keys(obj).forEach(key => self[key] = obj[key])
        // console.log('put==', self, window)
      }
      // console.log('put==', 4)
      // console.log('put>>', this.storage(window, obj['data_type'])[obj['id']], window)
      // console.log('put>>', self, window)
      if (subscribe) {
        this._subscribe([self])
        // console.log('IDS', this._ids())
      }
      return self
    }

    put(obj, window=null, subscribe=true) {
      // console.log('put<<', obj, window)
      if (Array.isArray(obj)) {
        // console.log('put', 1)
        return this._put_array(obj, window, subscribe)
      }
      return this._put_single(obj, window, subscribe)
    }

    get(data_type, id, window=null) {
      return this.storage(window, data_type)[id]
    }

    put_response(response, window=null, subscribe=true) {
      const stored_obj = this.put(response.data, window, subscribe)
      if (response.related_data) {
        Object.keys(response.related_data).forEach(data_type => {
          Object.keys(response.related_data[data_type]).forEach(key => {
            this.put(response.related_data[data_type][key], window)
          });
        });
      }

      // console.log(stored_obj)

      return stored_obj;
    }

    clr(window=null) {
      // console.log('clear<<', window)
      if (window === null) {
        // console.log('clear==', 1)
        this._unsubscribe(this._ids())
        this._storage = {}
      }
      else {
        // console.log('clear==', 2)
        if (this._storage[window]!== undefined) {
          this._unsubscribe(this._ids(window))
          delete this._storage[window]
        }
      }
      // console.log('IDS', this._ids())
    }

    // Возвращает объект ключами которого являются идентификаторы А значениями объекты с запрошенным data_type
    getList(data_type, window =null) {
      return this.storage(window, data_type)
    }
  }

  ctx.$services.storage = new StorageService()
  return ctx.$services.storage
}
