// Этот файл сгенерирован автоматически. Не надо его редактировать.
import accountApiMethods from  './accountApiMethods'
import companyApiMethods from  './companyApiMethods'
import devApiMethods from  './devApiMethods'
import identityApiMethods from  './identityApiMethods'
import inviteApiMethods from  './inviteApiMethods'
import userApiMethods from  './userApiMethods'
import authApiMethods from  './authApiMethods'
import genericApiMethods from  './genericApiMethods'
import bookingApiMethods from  './bookingApiMethods'
import buyerApiMethods from  './buyerApiMethods'
import commentApiMethods from  './commentApiMethods'
import fmsApiMethods from  './fmsApiMethods'
import leadApiMethods from  './leadApiMethods'
import organizationApiMethods from  './organizationApiMethods'
import personApiMethods from  './personApiMethods'
import tourApiMethods from  './tourApiMethods'
import agencyBalanceApiMethods from  './agencyBalanceApiMethods'
import agencyPaymentApiMethods from  './agencyPaymentApiMethods'
import agencyEntryApiMethods from  './agencyEntryApiMethods'
import balanceApiMethods from  './balanceApiMethods'
import hmsApiMethods from  './hmsApiMethods'
import occupationApiMethods from  './occupationApiMethods'
import reservationApiMethods from  './reservationApiMethods'
import periodApiMethods from  './periodApiMethods'
import roomApiMethods from  './roomApiMethods'
import bankAccountApiMethods from  './bankAccountApiMethods'
import commissionApiMethods from  './commissionApiMethods'
import contractApiMethods from  './contractApiMethods'
import documentTemplateApiMethods from  './documentTemplateApiMethods'
import entityApiMethods from  './entityApiMethods'
import mediaApiMethods from  './mediaApiMethods'
import messageApiMethods from  './messageApiMethods'
import ageApiMethods from  './ageApiMethods'
import attributeApiMethods from  './attributeApiMethods'
import refbookApiMethods from  './refbookApiMethods'
import categoryApiMethods from  './categoryApiMethods'
import diseaseApiMethods from  './diseaseApiMethods'
import hotelApiMethods from  './hotelApiMethods'
import mealApiMethods from  './mealApiMethods'
import programApiMethods from  './programApiMethods'
import shiftApiMethods from  './shiftApiMethods'
import hotelServiceApiMethods from  './hotelServiceApiMethods'
import scriptApiMethods from  './scriptApiMethods'
import telephonyApiMethods from  './telephonyApiMethods'
import versionApiMethods from  './versionApiMethods'
export const apiList = [
  accountApiMethods,
  companyApiMethods,
  devApiMethods,
  identityApiMethods,
  inviteApiMethods,
  userApiMethods,
  authApiMethods,
  genericApiMethods,
  bookingApiMethods,
  buyerApiMethods,
  commentApiMethods,
  fmsApiMethods,
  leadApiMethods,
  organizationApiMethods,
  personApiMethods,
  tourApiMethods,
  agencyBalanceApiMethods,
  agencyPaymentApiMethods,
  agencyEntryApiMethods,
  balanceApiMethods,
  hmsApiMethods,
  occupationApiMethods,
  reservationApiMethods,
  periodApiMethods,
  roomApiMethods,
  bankAccountApiMethods,
  commissionApiMethods,
  contractApiMethods,
  documentTemplateApiMethods,
  entityApiMethods,
  mediaApiMethods,
  messageApiMethods,
  ageApiMethods,
  attributeApiMethods,
  refbookApiMethods,
  categoryApiMethods,
  diseaseApiMethods,
  hotelApiMethods,
  mealApiMethods,
  programApiMethods,
  shiftApiMethods,
  hotelServiceApiMethods,
  scriptApiMethods,
  telephonyApiMethods,
  versionApiMethods,
]
