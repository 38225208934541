export default [
  {
    component: 'CNavItem',
    name: 'Главная',
    to: '/dashboard',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Демо',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        component: 'CNavItem',
        name: 'Рабочие страницы',
        icon: 'cil-description',
        visibilityLimitRole: ['superadmin'],
        items: [
          {
            component: 'CNavItem',
            name: 'Anton Test',
            to: '/workplace/anton1',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Anton2 Test',
            to: '/workplace/anton2',
            icon: 'cil-star',
          },
        ]
      },
      {
        component: 'CNavItem',
        name: 'Свои компоненты',
        icon: 'cil-description',
        visibilityLimitRole: ['superadmin'],
        items: [
          {
            component: 'CNavItem',
            name: 'Загрузка файлов',
            to: '/components/file_upload',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Phone input',
            to: '/components/phone-input',
            icon: 'cilPhone',
          },
          {
            component: 'CNavItem',
            name: 'Autocomplete',
            to: '/components/autocomplete',
            icon: 'cil-star',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Руководства',
        icon: 'cil-description',
        visibilityLimitRole: ['superadmin'],
        items: [
          {
            component: 'CNavItem',
            name: 'Хранилище',
            to: '/guides/storage',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Хранилище для таблицы',
            to: '/guides/storage_table',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Demo Table',
            to: '/guides/table',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Верстка',
            to: '/guides/common',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Demo Layout',
            to: '/guides/page',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Demo Form',
            to: '/guides/form',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Demo Multiform',
            to: '/guides/multiform',
            icon: 'cil-star',
          },
          {
            component: 'CNavItem',
            name: 'Demo Popup',
            to: '/guides/popup',
            icon: 'cil-star',
          },
        ],
      },
    ],
  },

  // новые добавлять сюда Агент - это пример
  {
    component: 'CNavItem',
    name: 'Агент',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to: '/agent/agent_fee',
        name: '1.8.2 Вознаграждение агента',
        component: 'CNavItem',
      },
      {
        to: '/agent/agent_profile',
        name: '1.8.1 Профайл агента',
        component: 'CNavItem',
      },
      {
        to: '/agent/agent_tours_list',
        name: '1.3.1 Список туров агента',
        component: 'CNavItem',
      },
      {
        to: '/agent/check_in_out',
        name: '1.7. Заезды и выезды',
        component: 'CNavItem',
      },
      {
        to: '/agent/hotel_find_helper',
        name: '1.5. Помощник по подбору отеля',
        component: 'CNavItem',
      },
      {
        to: '/agent/lead_edit',
        name: '1.1.2 Редактирование лида (агент)',
        component: 'CNavItem',
      },
      {
        to: '/agent/leads_list',
        name: '1.1.1 Список лидов (агент)',
        component: 'CNavItem',
      },
      {
        to: '/agent/leads_view',
        name: '1.1.3 Просмотр лида (агент)',
        component: 'CNavItem',
      },
      {
        to: '/agent/script_play',
        name: '1.2 Работа по скрипту',
        component: 'CNavItem',
      },
      {
        to: '/agent/tour_edit',
        name: '1.3.2 Создание/редактирование/операция с туром',
        component: 'CNavItem',
      },
      {
        to: '/agent/tour_search_demo',
        name: '1.6.1 Попап поиска по турам/лидам',
        component: 'CNavItem',
      },
      {
        to: '/agent/tour_search_results',
        name: ' 1.6.2 Результаты поиск по турам/лидам',
        component: 'CNavItem',
      },

    ]
  },
  {
    component: 'CNavItem',
    name: 'Агентство',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/agency/agency_tour_operate',
        name: "2.3.2 Создание/редактирование/операция с туром",
        component: 'CNavItem',
      },
      {
        to:'/agency/agency_tours_list',
        name:"2.3.1 Туры список туров агентства",
        component: 'CNavItem',
      },
      {
        to:'/agency/leads_list',
        name: "2.4. Лиды список",
        component: 'CNavItem',
      },
      {
        to:'/agency/operations_log',
        name: "2.1 Лог операций",
        component: 'CNavItem',
      },
      {
        to:'/agency/system_contracts',
        name: "2.6. Договора с НСБ",
        component: 'CNavItem',
      },
      {
        to:'/agency/tele_call_list',
        name: "2.5. Звонки список",
        component: 'CNavItem',
      },

    ]
  },
  {
    component: 'CNavItem',
    name: 'Вебмастер',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/webmaster/bookings_forms_edit',
        name:'3.1.2 Редактирование форм бронирования',
        component: 'CNavItem',      },
      {
        to:'/webmaster/bookings_forms_integration',
        name:'3.1.3 Интеграция формы бронирования',
        component: 'CNavItem',      },
      {
        to:'/webmaster/bookings_forms_list',
        name:'3.1.1 Список форм бронирования',
        component: 'CNavItem',
       },

    ]
  },
  {
    component: 'CNavItem',
    name: 'Бухгалтер агентства',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/agency_cc/agency_entries_list',
        name: "4.1.1 Список проводок агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_entry_create',
        name: "4.1.2 Создание проводки агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/export',
        name: "4.5 Экспорт",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_payments_create',
        name: "4.2.4 Создание платежа агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_payments_import',
        name: "4.2.2 Импорт платежей агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_payments_list',
        name: "4.2.1 Список платежей агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_payments_split',
        name: "4.2.3 Разнесение платежа агентства",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_reports',
        name: "4.4 Отчеты",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_system_documents',
        name: "4.8 Бухгалтерский документы с системой (сверки, акты)",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agency_system_settlements',
        name: "4.7 Взаиморасчеты с системой",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agent_fee_calculation_create',
        name: "4.6.1 Начисление вознаграждения агентам",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agent_fee_calculation_list',
        name: "4.6.3 Список начислений вознаграждения",
        component: 'CNavItem'
      },
      {
        to:'/agency_cc/agent_fee_list',
        name: "4.6.2 Список начисленных вознаграждений",
        component: 'CNavItem',
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Директор системы',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/system/companies_list',
        name: "5.1.1 Список компаний",
        component: 'CNavItem'
      },
      {
        to:'/system/company_edit',
        name: "5.1.2 редактирование компании",
        component: 'CNavItem'

      },
      {
        to:'/system/company_vue',
        name: "5.1.3 просмотр компании",
        component: 'CNavItem'

      },
      {
        to:'/system/user_list',
        name: "5.2.1 Список пользователей",
        component: 'CNavItem'
      },
    ]
  },
  {
    component: 'CNavItem',
    name: 'Бухгалтер системы',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/accountant/agency_create_entry',
        name: "6.1.2 Создание проводки системы",
        component: 'CNavItem'      },
      {
        to:'/accountant/agency_entries_list',
        name: "6.1.1 Список проводок системы ",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_agency_contract_edit',
        name: "6.8.2 Договор агентства",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_agency_contract_list',
        name: "6.8.1 Договора с агентствами",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_agency_plans_edit',
        name: "6.8.4 Тарифный план редактирование",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_agency_plans_list',
        name: "6.8.3 Тарифные планы список",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_documents',
        name: "6.3 Документы",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_export',
        name: "6.6 Экспорт",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_hotelier_contract_edit',
        name: "6.9.2 Договор отели редактирование",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_hotelier_contract_list',
        name: "6.9.1 Договора отели список",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_payment_create',
        name: "6.2.4 Создание платежа системы",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_payments_import',
        name: "6.2.2 Импорт платежей системы",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_payments_list',
        name: "6.2.1 Список платежей системы",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_payment_split',
        name: "6.2.3 Разнесение платежа системы",
        component: 'CNavItem'      },
      {
        to:'/accountant/system_reports',
        name: "6.4 Отчеты",
        component: 'CNavItem'      },
    ]
  },
  {
    component: 'CNavItem',
    name: 'Менеджер',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/manager/bookings_list',
        name:'6.7.1 Список броней',
        component: 'CNavItem',
        icon:'cilBold'
      },
      {
        to:'/manager/bookings_operate',
        name:'6.7.2 Операция с бронью',
        component: 'CNavItem',
        icon:'cilBrush'
      },

    ]
  },
  {
    component: 'CNavItem',
    name: 'Общее',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        to:'/common/profile',
        name:'8.1 Профайл',
        component: 'CNavItem',
        icon:'cilBold'
      },

    ]
  },


  {
    component: 'CNavItem',
    name: 'Старое',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        component: 'CNavItem',
        name: 'Пользователи',
        to: '/users',
        visibilityLimitRole: ['superadmin'],
        icon: 'cil-user',
      },
      {
        component: 'CNavItem',
        name: 'Отели',
        to: '/hotels',
        visibilityLimitRole: ['manager', 'accountant', "owner"],
        icon: 'cil-hotel',
      },
      {
        component: 'CNavItem',
        name: 'Договоры',
        icon: 'cil-description',
        visibilityLimitRole: ['superadmin'],
        items: [
          {
            component: 'CNavItem',
            name: 'Договоры с агентствами',
            to: '/contracts/agency',
          },
          {
            component: 'CNavItem',
            name: 'Договоры с отелями',
            to: '/contracts/hotel',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Чат',
        icon: 'cil-chat-bubble',
        visibilityLimitRole: ['superadmin'],
        items: [
          {
            component: 'CNavItem',
            name: 'Чат НСБ',
            to: '/chat',
          },
          {
            component: 'CNavItem',
            name: 'Обычный чат',
            to: '/chat',
          },
          {
            component: 'CNavItem',
            name: 'Чат с заявкой',
            to: '/applications/create',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Сотрудники',
        to: '/employees',
        icon: 'cil-user',
        visibilityLimitRole: ['manager', 'admin'],
      },
      {
        component: 'CNavItem',
        name: 'Компании',
        to: '/company',
        icon: 'cil-building',
        visibilityLimitRole: ['superadmin'],
      },
      {
        component: 'CNavItem',
        name: 'Юр. лица',
        to: '/entitys',
        icon: 'cil-people',
        visibilityLimitRole: ['superadmin'],
      },
      {
        component: 'CNavItem',
        name: 'Справка',
        to: '/info',
        icon: 'cil-info',
        visibilityLimitRole: ['manager', 'admin'],
      },
      /*  ----------------- Агент Start---------------*/
      {
        component: 'CNavItem',
        name: 'Заявки',
        to: '/applications',
        icon: 'cil-description',
        visibilityLimitRole: ['agent'],
        items: [
          {
            component: 'CNavItem',
            name: 'Cписок заявок',
            to: '/applications/',
          },
          {
            component: 'CNavItem',
            name: 'Скрипт',
            to: '/applications/create',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Туры',
        icon: 'cil-briefcase',
        visibilityLimitRole: ['agent'],
        items: [
          {
            component: 'CNavItem',
            name: 'Список туров',
            to: '/tours',
          },
          {
            component: 'CNavItem',
            name: 'Поиск по турам',
            to: '/tours',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Подбор отеля',
        to: '/hotel',
        icon: 'cil-building',
        visibilityLimitRole: ['agent'],
      },
      {
        component: 'CNavItem',
        name: 'Заезды и выезды',
        to: '/arrivals',
        icon: 'cil-car-alt',
        visibilityLimitRole: ['agent'],
      },
      /*  ----------------- Агент END---------------*/

      /*  ----------------- Владелец Start---------------*/
      {
        component: 'CNavItem',
        name: 'Лог операций',
        to: '/info',
        icon: 'cil-info',
        visibilityLimitRole: ['owner'],
      },
      {
        component: 'CNavItem',
        name: 'Заказчик',
        to: '/customer',
        icon: 'cil-user',
        visibilityLimitRole: ['owner'],
      },
      {
        component: 'CNavItem',
        name: 'Туры',
        to: '/customer',
        icon: 'cil-briefcase',
        visibilityLimitRole: ['owner'],
      },
      {
        component: 'CNavItem',
        name: 'Компании',
        to: '/company',
        icon: 'cil-building',
        visibilityLimitRole: ['owner'],
      },
      {
        component: 'CNavItem',
        name: 'Договоры',
        to: '/contracts',
        icon: 'cil-description',
        visibilityLimitRole: ['owner'],
      },
      {
        component: 'CNavItem',
        name: 'Звонки',
        to: '/calls',
        icon: 'cil-phone',
        visibilityLimitRole: ['owner'],
      },
      /*  ----------------- Владелец end---------------*/
      /*  ----------------- Вебмастер Start---------------*/
      {
        component: 'CNavItem',
        name: 'Формы',
        to: '/forms',
        icon: 'cil-file-code',
        visibilityLimitRole: ['webmaster'],
      },
      /*  ----------------- Вебмастер end---------------*/
      /*  ----------------- Бухгалтер start---------------*/
      {
        component: 'CNavItem',
        name: 'Проводки',
        to: '/entries',
        icon: 'cil-file-code',
        visibilityLimitRole: ['accountant'],
      },
      {
        component: 'CNavItem',
        name: 'Платежи',
        to: '/payments',
        icon: 'cil-money',
        visibilityLimitRole: ['accountant'],
        items: [
          {
            component: 'CNavItem',
            name: 'Список платежей',
            to: '/payments',
          },
          {
            component: 'CNavItem',
            name: 'Импорт платежей',
            to: '/payments',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Док. по клиентам',
        to: '/docs',
        icon: 'cil-description',
        visibilityLimitRole: ['accountant'],
      },
      {
        component: 'CNavItem',
        name: 'Отчеты',
        to: '/reports',
        icon: 'cil-description',
        visibilityLimitRole: ['accountant'],
      }
      /*  ----------------- Бухгалтер end---------------*/
    ]
  },


  {
    component: 'CNavItem',
    name: 'CORE UI',
    icon: 'cil-description',
    visibilityLimitRole: ['superadmin'],
    items: [
      {
        component: 'CNavTitle',
        name: 'Theme',
      },
      {
        component: 'CNavItem',
        name: 'Colors',
        to: '/theme/colors',
        icon: 'cil-drop',
      },
      {
        component: 'CNavItem',
        name: 'Typography',
        to: '/theme/typography',
        icon: 'cil-pencil',
      },
      {
        component: 'CNavTitle',
        name: 'Components',
      },
      {
        component: 'CNavGroup',
        name: 'Base',
        to: '/base',
        icon: 'cil-puzzle',
        items: [
          {
            component: 'CNavItem',
            name: 'Accordion',
            to: '/base/accordion',
          },
          {
            component: 'CNavItem',
            name: 'Breadcrumbs',
            to: '/base/breadcrumbs',
          },
          {
            component: 'CNavItem',
            name: 'Cards',
            to: '/base/cards',
          },
          {
            component: 'CNavItem',
            name: 'Carousels',
            to: '/base/carousels',
          },
          {
            component: 'CNavItem',
            name: 'Collapses',
            to: '/base/collapses',
          },
          {
            component: 'CNavItem',
            name: 'List Groups',
            to: '/base/list-groups',
          },
          {
            component: 'CNavItem',
            name: 'Navs & Tabs',
            to: '/base/navs',
          },
          {
            component: 'CNavItem',
            name: 'Paginations',
            to: '/base/paginations',
          },
          {
            component: 'CNavItem',
            name: 'Placeholders',
            to: '/base/placeholders',
          },
          {
            component: 'CNavItem',
            name: 'Popovers',
            to: '/base/popovers',
          },
          {
            component: 'CNavItem',
            name: 'Progress',
            to: '/base/progress',
          },
          {
            component: 'CNavItem',
            name: 'Spinners',
            to: '/base/spinners',
          },
          {
            component: 'CNavItem',
            name: 'Tables',
            to: '/base/tables',
          },
          {
            component: 'CNavItem',
            name: 'Tooltips',
            to: '/base/tooltips',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Buttons',
        to: '/buttons',
        icon: 'cil-cursor',
        items: [
          {
            component: 'CNavItem',
            name: 'Buttons',
            to: '/buttons/standard-buttons',
          },
          {
            component: 'CNavItem',
            name: 'Button Groups',
            to: '/buttons/button-groups',
          },
          {
            component: 'CNavItem',
            name: 'Dropdowns',
            to: '/buttons/dropdowns',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Forms',
        to: '/forms',
        icon: 'cil-notes',
        items: [
          {
            component: 'CNavItem',
            name: 'Form Control',
            to: '/forms/form-control',
          },
          {
            component: 'CNavItem',
            name: 'Select',
            to: '/forms/select',
          },
          {
            component: 'CNavItem',
            name: 'Multi Select',
            to: '/forms/multi-select',
            badge: {
              color: 'danger',
              text: 'PRO',
            },
          },
          {
            component: 'CNavItem',
            name: 'Checks & Radios',
            to: '/forms/checks-radios',
          },
          {
            component: 'CNavItem',
            name: 'Range',
            to: '/forms/range',
          },
          {
            component: 'CNavItem',
            name: 'Input Group',
            to: '/forms/input-group',
          },
          {
            component: 'CNavItem',
            name: 'Floating Labels',
            to: '/forms/floating-labels',
          },
          {
            component: 'CNavItem',
            name: 'Layout',
            to: '/forms/layout',
          },
          {
            component: 'CNavItem',
            name: 'Validation',
            to: '/forms/validation',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Icons',
        to: '/icons',
        icon: 'cil-star',
        items: [
          {
            component: 'CNavItem',
            name: 'CoreUI Icons',
            to: '/icons/coreui-icons',
            badge: {
              color: 'info',
              text: 'NEW',
            },
          },
          {
            component: 'CNavItem',
            name: 'Brands',
            to: '/icons/brands',
          },
          {
            component: 'CNavItem',
            name: 'Flags',
            to: '/icons/flags',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Notifications',
        to: '/notifications',
        icon: 'cil-bell',
        items: [
          {
            component: 'CNavItem',
            name: 'Alerts',
            to: '/notifications/alerts',
          },
          {
            component: 'CNavItem',
            name: 'Badges',
            to: '/notifications/badges',
          },
          {
            component: 'CNavItem',
            name: 'Modals',
            to: '/notifications/modals',
          },
        ],
      },
      {
        component: 'CNavItem',
        name: 'Widgets',
        to: '/widgets',
        icon: 'cil-calculator',
        badge: {
          color: 'info',
          text: 'NEW',
        },
      },
      {
        component: 'CNavItem',
        name: 'Smart Table',
        to: '/smart-table',
        icon: 'cil-grid',
        badge: {
          color: 'danger',
          text: 'PRO',
        },
      },
      {
        component: 'CNavTitle',
        name: 'Plugins',
      },
      {
        component: 'CNavItem',
        name: 'Calendar',
        to: '/calendar',
        icon: 'cil-calendar',
        badge: {
          color: 'danger',
          text: 'PRO',
        },
      },
      {
        component: 'CNavItem',
        name: 'Charts',
        to: '/charts',
        icon: 'cil-chart-pie',
      },
      {
        component: 'CNavTitle',
        name: 'Extras',
      },
      {
        component: 'CNavGroup',
        name: 'Pages',
        to: '/pages',
        icon: 'cil-star',
        items: [
          {
            component: 'CNavItem',
            name: 'Login',
            to: '/pages/login',
          },
          {
            component: 'CNavItem',
            name: 'Register',
            to: '/pages/register',
          },
          {
            component: 'CNavItem',
            name: 'Error 404',
            to: '/pages/404',
          },
          {
            component: 'CNavItem',
            name: 'Error 500',
            to: '/pages/500',
          },
        ],
      },
      {
        component: 'CNavGroup',
        name: 'Apps',
        to: '/apps',
        icon: 'cil-layers',
        items: [
          {
            component: 'CNavGroup',
            name: 'Invoicing',
            to: '/apps/invoicing',
            icon: 'cil-spreadsheet',
            items: [
              {
                component: 'CNavItem',
                name: 'Invoice',
                to: '/apps/invoicing/invoice',
                badge: {
                  color: 'danger',
                  text: 'PRO',
                },
              },
            ],
          },
          {
            component: 'CNavGroup',
            name: 'Email',
            to: '/apps/email',
            icon: 'cil-envelope-closed',
            items: [
              {
                component: 'CNavItem',
                name: 'Inbox',
                to: '/apps/email/inbox',
                icon: 'cil-envelope-closed',
                badge: {
                  color: 'danger',
                  text: 'PRO',
                },
              },
              {
                component: 'CNavItem',
                name: 'Message',
                to: '/apps/email/message',
                icon: 'cil-envelope-open',
                badge: {
                  color: 'danger',
                  text: 'PRO',
                },
              },
              {
                component: 'CNavItem',
                name: 'Compose',
                to: '/apps/email/compose',
                icon: 'cil-envelope-letter',
                badge: {
                  color: 'danger',
                  text: 'PRO',
                },
              },
            ],
          },
        ],
      },

    ]
  },

]

