// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class TelephonyApiMethodsCls {
    async telecallList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка телефонных звонков
      // filters: created_at, identity, phone, started_at, stopped_at, ring_duration, call_duration,
      //          event_type, tele_call_type
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('telecallList')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.telecall.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async telecallCreate(wh, data) {
      // data: TeleCallCreate
      // Создание телефонного звонка
      console.log('telecallCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.telecall.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.telephonyApi = new TelephonyApiMethodsCls()
}

