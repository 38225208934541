import { useAPI } from '@/services'
import endpoints from '@/config/api-endpoints'

async function cloneToken(data) {
  return await useAPI.request({ config: endpoints.auth.cloneToken, body: data })
}

async function refreshToken(data) {
  const result =  await useAPI.request({ config: endpoints.auth.refreshToken, body: data })

  const finishToken = Date.parse(result.data.valid_till)
  const startToken = localStorage.getItem('operationTime')

  if (finishToken < startToken) {
    let refreshedToken = await refreshToken({
      refresh_token: this.account.refresh_key
    })
    await this.$store.dispatch('user/SET_PROFILE', refreshedToken.data)
  }
  return result
}

export { refreshToken, cloneToken }
