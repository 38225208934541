import store from '@/store/index'
import axios from 'axios'

export default ctx => {
  class FetchService {
    async request({config = {}, body = {}, file = null}) {
      const data = {...this.#prepareRequest({config, body})}

      if (file) {
        data.onUploadProgress = function (progressEvent) {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          )

          if (file) {
            file.percentage = percentCompleted
          }
          return percentCompleted
        }
      }

      console.log('FetchService request .... ')
      const response = await axios(data)


      console.log('FetchService 1 ')

      if (file) {
        file.id = response?.data?.data?.hash
      }

      console.log('FetchService 2')

      return this.#responseHandler(response, await response.data)

    }

    #prepareRequest({config = {}, body = {}}) {
      let {
        url,
        method = 'GET',
        params = {},
        json = true,
        customServer = ''
      } = config
      if (body) {
        if (json) {
          body = JSON.stringify(body)
        } else {
          const formData = new FormData()
          Object.keys(body).forEach((key) => formData.append(key, body[key]))
          body = formData
        }
      }

      const headers = {}
      if (json) {
        headers['Content-Type'] = 'application/json'
      }
      const token = ctx.$services.auth.getTokenKey()
      if (token) {
        headers['authorization'] = token
      }
      if (params) {
        params = Object.keys(params).length ? new URLSearchParams(params) : ''
      } else {
        params = ''
      }
      const server = customServer ? customServer : store.getters.baseUrl
      url = `${server}/${url}?${params.toString()}`
      return {
        method: method,
        url: url,
        data: body,
        headers: headers,
      }
    }

    #responseHandler(response, data) {
      if (!response || !response.status === 200 || !response.statusText === 'OK') {
        if (data.detail) {
          throw new Error(data.detail || 'Что-то пошло не так')
        } else {
          /*
            Дальше бога нет =)
            Я честно не хотел так писать меня заставили
          */
          let allErrors = ''
          Object.keys(data).forEach((error) => {
            if (data[error].constructor.name == 'Object') {
              Object.keys(data[error]).forEach((errorDeep) => {
                if (data[error][errorDeep].constructor.name == 'Array') {
                  allErrors =
                    allErrors +
                    (allErrors ? '<br />' : '') +
                    data[error][errorDeep].join()
                }
              })
            }
          })
          throw new Error(allErrors || 'Что-то пошло не так')
        }
      }
      return data
    }
  }

  console.log('FetchService created')
  ctx.$services.fetch = new FetchService()
}
