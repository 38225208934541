import {
  getUsers, getUser, authInfo, getAccountComapny,
} from '@/api/userMethods'

import {cloneToken} from '@/api/tokenMethods'

const state = () => ({
  user: null,
  account: null,
  company: null,
  role: null,
  userCompany: null,
  users: [],
})

const getters = {
  user: (state) => {
    return state.user
  },
  users: (state) => {
    return state.user
  },
  account: (state) => {
    return state.account
  },
  role: (state) => {
    return state.role
  },
}

const mutations = {
  SET_USER(state, payload) {
    state.user = payload
    console.log('------------------')
    console.log(payload)
    console.log('------------------')
  },
  SET_USER_COMPANY(state, payload) {
    state.company = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_ROLE(state, payload) {
    state.role = payload
  },
  SET_ACCOUNT(state, payload) {
    state.account = payload
  },
}

const actions = {

  async SET_PROFILE({commit}, payload) {
    commit('SET_ACCOUNT', payload)
  },

  async SET_IDENTITYS({commit}, payload) {
    const response = payload ? payload : await getUsers()
    commit('SET_IDENTITYS', response)
  },

  // Action , commited on user auth.
  async SET_AUTH({commit}, payload) {

    let response = payload ? payload : await authInfo()

    commit('SET_ACCOUNT', response.data)
    commit('SET_ROLE', response.data.identity)
    // related_data может отсутствовать
    // Если related data отсутствует то делается запрос
    const user = response?.related_data['account__user']
      ? response?.related_data['account__user']
      : await getUser(response.data.identity.user)
    console.log('response.data.identity.user', response.data.identity.user)
    console.log('user', user[response.data.identity.user])
    commit('SET_USER', user[response.data.identity.user])

    const company = response?.related_data['account__company']
      ? response?.related_data['account__company']
      : await getAccountComapny()
    commit('SET_USER_COMPANY', company)

    // Clone token
    let clonnedToken = await cloneToken({
      refresh_token: this.state.user.account.refresh_key
    })
    localStorage.setItem('token', clonnedToken.data.key)
      localStorage.setItem('operationTime', Date.parse(new Date()))
    localStorage.setItem('refresh_till', clonnedToken.data.refresh_till)
    return commit('SET_ACCOUNT', clonnedToken.data);


  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
