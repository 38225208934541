// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BuyerApiMethodsCls {
    async buyerList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Buyer
      // filters: created_at, updated_at, company, owner, responsible, person, organization,
      //          cr_limit
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('buyerList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async buyerCreate(wh, data) {
      // data: BuyerCreate
      // Создание Buyer
      console.log('buyerCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async buyerRetrieve(wh, id) {
      // Получение Buyer
      console.log('buyerRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('core__buyer', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'core__buyer', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async buyerUpdate(wh, id, data) {
      // data: BuyerCreate
      // Обновление Buyer
      console.log('buyerUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async buyerCrLimitPartial(wh, id, data) {
      // data: BuyerChangeCrLimit
      // Изменение cr_limit у Buyer
      console.log('buyerCrLimitPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.crLimit.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async buyerPersonPartial(wh, id, data) {
      // data: BuyerChangePerson
      // Изменение person и(или) organization у Buyer
      console.log('buyerPersonPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.person.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async buyerResponsiblePartial(wh, id, data) {
      // data: BuyerChangeResponsible
      // Изменение responsible у Buyer
      console.log('buyerResponsiblePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.buyer.responsible.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.buyerApi = new BuyerApiMethodsCls()
}

